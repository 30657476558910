<script setup lang="ts">
import { useRouter } from 'vue-router/composables'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page.vue'
import DrawerActivityList from '/~/components/drawer/components/activity/drawer-activity-list.vue'

const router = useRouter()

function onViewDetail(id: string) {
  router.push({ hash: `#profile-activity/${id}` })
}
</script>

<template>
  <base-aside-page title="Activity" no-padding>
    <drawer-activity-list new-design @view-detail="onViewDetail" />
  </base-aside-page>
</template>
