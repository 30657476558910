<script setup lang="ts">
import { computed } from 'vue'
import { formatDate } from '/~/utils/format/date'
import { formatDollar } from '/~/utils/format/money'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { Activity } from '/~/composables/activity'

const props = defineProps<{ item: Activity }>()

const emit = defineEmits<{
  (event: 'click', id: string): void
}>()

const canSeeDetails = computed(() => {
  return (
    !props.item.isTypeTopUpOrder &&
    !props.item.isTypeUserCashbackOrder &&
    !props.item.isTypePointsTransaction &&
    !props.item.isTypeQrcScan
  )
})

const title = computed(() => {
  if (props.item.isTypeUserCashbackOrder) {
    return props.item.externalOrderId
  } else if (props.item.isTypePointsTransaction) {
    return `${props.item.paymentAmount ?? ''} Points ${
      props.item.subtype ?? ''
    }`.trim()
  } else if (props.item.isTypeQrcScan) {
    return props.item.title
  }

  return props.item.description[0]
})

const getFundingSource = (code: string) => {
  switch (code) {
    case 'direct_debit':
      return 'Credit/Debit Card'
    case 'direct_deposit':
      return 'Bank Account'
    case 'coupon':
      return 'Coupon code'
  }
}

const subtitle = computed(() => {
  if (props.item.isTypeTopUpOrder) {
    return getFundingSource(props.item.fundingSource)
  } else if (props.item.isTypeUserCashbackOrder) {
    return `${props.item.merchantName} cashback`
  } else if (props.item.isTypePointsTransaction) {
    return null
  } else if (props.item.isTypeQrcScan) {
    return props.item.businessAsset
  }

  return props.item.description?.[1]
})

const sign = computed(() => {
  if (props.item.transactionType === 'credit') {
    return '+'
  } else if (props.item.transactionType === 'debit') {
    return '-'
  } else if (props.item.isTypeCashTransaction) {
    return '+'
  }

  return ''
})

const value = computed(() => {
  if (props.item.isTypeUserCashbackOrder) {
    return props.item.userCashbackAmount
  } else if (props.item.isTypeCashbackReconciliationFeed) {
    return props.item.cashback
  }
  return props.item.total
})

const valueWithSign = computed(() => {
  if (props.item.isTypeQrcScan) {
    return formatDate('daymonthyear', props.item.happenedAt)
  }

  if (props.item.isTypePointsTransaction) {
    return Number(value.value).toFixed(0)
  }

  return `${sign.value}${formatDollar(value.value)}`
})

const cashbackStatus = computed(() => {
  const status = props.item.fundingStatus || props.item.status

  switch (status) {
    case 'ineligible':
    case 'vendor_defaulted':
      return 'ineligible'
    case 'voided':
      return 'voided'
    case 'cancelled':
      return 'cancelled'
    case 'funded':
    case 'paid':
      return 'completed'
    default:
      return 'pending'
  }
})

const subvalue = computed(() => {
  if (props.item.isTypePaymentOrder) {
    return props.item.status !== 'completed' ? props.item.status : ''
  } else if (
    props.item.isTypeUserCashbackOrder ||
    props.item.isTypeCashbackReconciliationFeed
  ) {
    return cashbackStatus.value !== 'completed' ? cashbackStatus.value : ''
  } else if (props.item.isTypeQrcScan) {
    return formatDate('time', props.item.happenedAt)
  }

  return ''
})

const activityStatus = computed(() => {
  if (props.item.isTypePaymentOrder) {
    return props.item.status
  } else if (
    props.item.isTypeUserCashbackOrder ||
    props.item.isTypeCashbackReconciliationFeed
  ) {
    return cashbackStatus.value
  }

  return ''
})

const icon = computed(() => {
  if (props.item.isTypeTopUpOrder || props.item.isTypeTopUpTransaction) {
    return 'plain/ewallet-plus'
  } else if (props.item.isTypePointsTransaction) {
    return 'points/token'
  } else if (props.item.isTypeQrcScan) {
    return 'qr/gain-access'
  }

  return 'menu/label'
})

const isPending = computed(() => activityStatus.value === 'pending')
const isFailed = computed(() => {
  return /ineligible|voided|cancelled/.test(activityStatus.value)
})
const isCompleted = computed(() => !isPending.value && !isFailed.value)

const onClick = () => {
  if (!canSeeDetails.value) return

  emit('click', props.item.id)
}
</script>

<template>
  <div
    class="flex items-center justify-between"
    :class="{
      'cursor-pointer': canSeeDetails,
    }"
    @click="onClick"
  >
    <div
      class="mr-[15px] flex h-11 w-11 shrink-0 items-center justify-center rounded-full text-white"
      :class="{
        'bg-fg-warning': isPending,
        'bg-fg-error': isFailed,
        'bg-primary': isCompleted,
      }"
    >
      <base-icon :svg="icon" size="md" />
    </div>
    <div class="w-full min-w-0 py-[5px]">
      <div class="truncate font-bold">
        {{ title }}
      </div>
      <div class="truncate text-sm leading-6 text-eonx-neutral-600">
        {{ subtitle }}
      </div>
    </div>
    <div class="ml-5 shrink-0 text-right">
      <div class="font-bold">
        {{ valueWithSign }}
      </div>
      <div
        class="max-w-full overflow-hidden break-words text-sm capitalize leading-6"
        :class="{
          'text-eonx-neutral-600': !activityStatus,
          'text-fg-error': isFailed,
          'text-fg-warning': isPending,
        }"
      >
        {{ subvalue }}
      </div>
    </div>
  </div>
</template>
